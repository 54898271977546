import React from "react"

import SEO from "../components/seo"
import PageHeader from "../components/page-header"
import ContentContact from "../components/content-contact"
import data from "../data/contact.yaml"
const ContactPage = props => {
  const {
    pageContext: { lang },
  } = props
  return (
    <>
      <SEO title={data.title[lang]} />
      <PageHeader
        headerImage={data.headerImage}
        headerAlt={data.headerAlt}
        title={data.title[lang]}
        subTitle={data.subtitle[lang]}
        info={data.info}
      />
      <ContentContact
        contentHeader={data.content.header[lang]}
        content={data.content.text}
        contentMap={data.content.map}
      />
    </>
  )
}

export default ContactPage
