import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import Markdown from "markdown-to-jsx"

import theme from "./theme"

const Section = styled.section`
  padding: 2rem 0;
  ${theme.breakpoint.md} {
    padding: 3rem 0;
  }
`
export default function ContentReataurantBar(props) {
  return (
    <>
      <Section>
        <Container>
          <Row>
            <Col
              md="5"
              className="ml-auto d-flex flex-column justify-content-center"
            >
              <h2 className="h3 text-info">{props.title}</h2>
              <Markdown>{props.content}</Markdown>
            </Col>
            <Col md="6" lg="5" className="mr-auto">
            <Markdown>{props.contentMap}</Markdown>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}
